@import url(https://rsms.me/inter/inter.css);

body { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  body { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-avatar.ant-avatar > img{
  height:auto;
}

.ant-card-head-title.ant-card-head-title{
  white-space: normal;
  white-space: initial;
}
